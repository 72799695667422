.overview-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  flex-direction: column;
  padding: 20px;

  .overview-page-title {
    padding: 10px;
    font-size: var(--text-bigger);
  }

  .overview-page-content {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .overview-page-item {
      display: flex;
      flex-direction: column;
      border: 1px solid white;
      border-radius: var(--border-radius);
      width: 200px;
      padding: 10px;
      height: 250px;
      cursor: pointer;
      margin: 10px;
      box-shadow: var(--shadow);
      transition: var(--transition);

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .overview-page-item-title {
        text-align: center;
        font-size: var(--text-big);
        margin-bottom: 10px;
        border-bottom: 1px solid white;
      }

      .overview-page-item-description {
        text-align: center;
        font-size: var(--text-normal);
      }
    }
  }
}
