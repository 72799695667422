.app {
  background-color: var(--background-color);
}

html,
body,
#root,
.app {
  height: 100vh;
  width: 100vw;
}
