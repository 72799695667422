body,
html {
  background: var(--background-color);
  font-size: 15px;

  font-family: "Ubuntu", sans-serif;
  color: var(--color);
  position: relative;
}

li {
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
}

.error {
  border: 2px solid var(--error);
  background: var(--error);
  padding: 5px;
  border-radius: 4px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

.scrollingDisabled {
  overflow-y: hidden;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.6);
  border-radius: 15px;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  display: none;
}

* {
  scrollbar-color: rgba(128, 128, 128, 0.6) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

.success {
  color: var(--success);
}
.warning {
  color: var(--warning);
}
.error {
  color: var(--error);
}
