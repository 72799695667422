.personal-information {
  display: flex;

  .personal-profile-picture {
    padding: var(--padding);

    img {
      border-radius: var(--border-radius);
    }
  }

  .personal-profile-infos {
    padding: var(--padding);
    .personal-profile-infos-name {
      border-bottom: var(--border-width) solid var(--border-color-strong);
      padding-bottom: var(--padding-small);
      .personal-profile-infos-name-fullname {
        font-size: var(--text-big);
      }

      .personal-profile-infos-name-name {
        color: var(--color-fine);
      }
    }
    .personal-profiles-infos-additional {
      padding: var(--padding);

      .personal-profiles-infos-additional-item {
        display: flex;

        .personal-profiles-infos-additional-item-label {
          color: var(--color-fine);
          margin: var(--margin) var(--margin) var(--margin) 0;
        }

        .personal-profiles-infos-additional-item-value {
          margin: var(--margin) 0 var(--margin) 0;
        }
      }
    }
  }
}

.change-password {
  display: flex;

  .change-password-fields {
    padding: var(--padding);
  }

  .change-password-validations {
    padding: var(--padding);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .change-password-validations-item {
      margin-bottom: var(--margin-small);
      &.NEUTRAL {
        color: var(--color-fine);
      }

      &.INVALID {
        color: var(--error);
      }

      &.VALID {
        color: var(--success);
      }
    }
  }
}
