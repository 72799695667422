:root {
  /* =======================================
    
          TEXT
    
      ======================================= */
  //Color
  --color: black;
  --color-header: #ca0a16;
  --color-header-fine: rgba(255, 0, 0, 0.6);
  --color-header-raw: 0, 208, 214;
  --color-secondary: black;
  --color-fine: rgba(0, 0, 0, 0.6);

  //Size
  --text-bigger: 28px;
  --text-big: 22px;
  --text-normal: 15px;

  /* =======================================
    
          COLORS
    
      ======================================= */
  --success: rgb(25, 145, 14);
  --warning: rgba(255, 251, 33, 1);
  --error: rgba(255, 64, 80, 1);
  --disabled: rgba(100, 100, 100, 0.6);

  --background-color: #ffff00;
  --background-color-item: white;
  --background-color-hover: rgba(0, 0, 0, 0.2);
  --background-color-selected: rgba(0, 0, 0, 0.3);
  --background-color-disabled: rgba(0, 0, 0, 0.6);
  --background-color-highlighted: rgba(0, 0, 0, 0.1);
  --background-color-item-popup: rgba(230, 230, 230, 1);

  /* =======================================
    
          STYLE
    
      ======================================= */
  --border-radius: 5px;
  --border-radius-small: 2px;
  --border-width: 1px;
  --border-color-strong: rgba(0, 0, 0, 0.6);
  --border: 1px solid rgba(0, 0, 0, 0.3);

  --shadow-color: var(--color-header);
  --shadow: 0px 0px 10px var(--shadow-color);
  --inner-shadow: inset 0px 0px 10px var(--shadow-color);

  --padding: 10px;
  --padding-small: 5px;
  --padding-smaller: 3px;
  --margin: 5px;
  --margin-big: 10px;
  --margin-small: 3px;

  /* =======================================
    
          ANIMATIONS
    
      ======================================= */
  --transition: 0.15s all ease-in-out;
  --fast-transition: 0.075s all ease-in-out;
  --slow-transition: 0.3s all ease-in-out;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* =======================================
    
            TEXT
    
        ======================================= */
    //Color
    --color: white;
    --color-header: #ca0a16;
    --color-header-fine: rgba(255, 0, 0, 0.6);
    --color-header-raw: 0, 208, 214;
    --color-secondary: black;
    --color-fine: rgba(255, 255, 255, 0.6);

    //Size
    --text-bigger: 28px;
    --text-big: 22px;
    --text-normal: 15px;

    /* =======================================
    
            COLORS
    
        ======================================= */
    --success: rgb(25, 145, 14);
    --warning: rgba(255, 251, 33, 1);
    --error: rgba(255, 64, 80, 1);
    --disabled: rgba(100, 100, 100, 0.6);

    --background-color: black;
    --background-color-item: rgb(60, 60, 0);
    --background-color-hover: rgba(255, 255, 255, 0.2);
    --background-color-selected: rgba(255, 255, 255, 0.3);
    --background-color-disabled: rgba(0, 0, 0, 0.6);
    --background-color-highlighted: rgba(255, 255, 255, 0.1);
    --background-color-item-popup: rgba(100, 100, 100, 1);

    /* =======================================
    
            STYLE
    
        ======================================= */
    --border-radius: 5px;
    --border-radius-small: 2px;
    --border-width: 1px;
    --border-color-strong: rgba(255, 255, 255, 0.6);
    --border: 1px solid rgba(255, 255, 255, 0.3);

    --shadow-color: var(--color-header);
    --shadow: 0px 0px 2px var(--shadow-color);
    --inner-shadow: inset 0px 0px 2px var(--shadow-color);

    --padding-big: 15px;
    --padding: 10px;
    --padding-small: 5px;
    --padding-smaller: 3px;
    --margin: 5px;
    --margin-big: 10px;
    --margin-small: 3px;

    /* =======================================
    
            ANIMATIONS
    
        ======================================= */
    --transition: 0.15s all ease-in-out;
    --fast-transition: 0.075s all ease-in-out;
    --slow-transition: 0.3s all ease-in-out;
  }
}
