.request-drive-pickup {
  display: flex;

  .request-drive-pickup-additional {
    margin-left: var(--margin-big);
  }
}

.request-derive-general {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  align-items: center;

  .form {
    max-width: fit-content;

    &:first-child {
      margin-bottom: var(--margin-big);
    }
  }
}
