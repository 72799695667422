.dashboard {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 75px auto;
  grid-template-rows: min-content auto;
  grid-template-areas:
    "horizontal horizontal"
    "vertical content";

  .dashboard-horizontal-nav {
    display: inline;
    grid-area: horizontal;
    box-shadow: 77px 0 5px var(--shadow-color);
    z-index: 1;
  }

  .dashboard-vertical-nav {
    grid-area: vertical;
    box-shadow: 0 0 5px var(--shadow-color);
  }

  .dashboard-content {
    grid-area: content;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dashboard-vertical-nav-btn {
    display: none;
    background-color: var(--background-color-item);
  }

  .dashboard-vertical-nav-placeholder {
    grid-area: navplaceholder;
    display: none;
    background-color: var(--background-color-item);
  }

  @media (max-width: 500px) {
    grid-template-columns: max-content auto;
    grid-template-rows: max-content auto;
    grid-template-areas:
      "navplaceholder horizontal"
      "vertical content";

    .dashboard-vertical-nav-btn {
      display: inline-flex;
    }
    .dashboard-horizontal-nav {
      display: inline-flex;
    }
    .dashboard-vertical-nav-placeholder {
      display: inline-block;
    }
    .dashboard-vertical-nav {
      transition: margin 0.5s;
      margin-left: -75px;

      &.nav-open {
        margin-left: 0px;
      }
    }
  }
}
