.db-selector {
  .db-selector-forms {
    display: flex;
    .db-selector-form {
      padding: var(--padding);

      .db-selector-title {
        display: flex;
        justify-content: center;
        margin-bottom: var(--margin-big);
      }
    }
  }
  .db-selector-footer {
    display: flex;
    justify-content: center;
  }
}

.schematables {
  display: flex;
  flex-direction: column;

  .schematables-items {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .schematables-item {
      padding: var(--padding);
      background-color: var(--background-color-highlighted);
      margin: var(--margin);
      border-radius: var(--border-radius);

      .schematables-item-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: var(--padding);

        .schematables-item-header-title {
          font-size: var(--text-big);
        }

        .schematables-item-header-anzeige {
          color: var(--color-fine);
        }
      }
    }
  }

  .schematables-footer {
    display: block;
    padding: var(--padding);
    * {
      margin: 0 auto;
    }

    button {
      display: block;
      margin-top: var(--margin);
    }
  }
}

.diff-shower {
  display: flex;

  .diff-shower-content {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;

    .diff-shower-item {
      padding: var(--padding);
      background-color: var(--background-color-highlighted);
      margin-left: var(--margin);
      border-radius: var(--border-radius);

      .diff-shover-item-title {
        display: flex;
        font-size: var(--text-big);
        justify-content: center;
        padding: var(--padding);
      }
    }
  }
}

.script-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .script-dialog-content {
    display: block;
    max-width: 100%;
    overflow: hidden;

    code {
      > span {
        flex-wrap: wrap;
      }
    }
  }
}
